<template>
  <div class="evaluate">
    <nav-bar title="全部评价" left-text left-arrow @click-left="onClickLeft" />
    <div class="back">
      <div class="content" v-for="(item, index) in commitList" :key="index">
        <div class="top">
          <div class="top-lft">
            <div class="header">
              <img :src="item.user.headimgurl" alt="" v-if="item.user.headimgurl" />
              <img src="@static/home/photo.png" alt="" v-else />
            </div>
            <div class="name">
              <span style="font-weight: bold">{{ item.user.username }}</span>
              <rate v-model="item.score" size="12" readonly />
            </div>
          </div>
          <div class="top-rgt">
            <span class="time">{{ item.create_time }}</span>
          </div>
        </div>
        <div class="text">
          <span>{{ item.content }}</span>
        </div>
        <div class="btm">
          <swipe :loop="false" :width="120" :show-indicators="false">
            <swipe-item v-for="(img, idx) in item.images" :key="idx"><img :src="img" alt=""/></swipe-item>
          </swipe>
        </div>
        <div class="reply">
          <div class="headTxt">商家回复:</div>
          <div class="reolyCont">
            {{item.reply_content}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Goods from "@api/goods";
import { NavBar, Swipe, SwipeItem, Rate } from "vant";
export default {
  components: {
    NavBar,
    Swipe,
    SwipeItem,
    Rate
  },
  data() {
    return {
      value: 2,
      commitList: [],
      id: this.$route.query.id
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async getComment() {
      try {
        const res = await Goods.getComment(this.id, 1, 20);
        this.commitList = res.data;
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    this.getComment();
  }
};
</script>

<style lang="scss" scoped>
.evaluate {
  .back {
    padding: 5px 14px;
  }
  .content {
    margin-top: 8px;
    border-bottom: 1px solid #dbdbdb3d;
    padding-bottom: 10px;
    .top {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      .top-lft {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .header {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          display: flex;
          flex-direction: column;
        }
      }
      .top-rgt {
        color: #8c8c8c;
      }
    }
    .text {
      margin-top: 6px;
      line-height: 18px;
    }
    .btm {
      margin-top: 6px;
      // height: 85px;
      img {
        width: 90%;
      }
    }
    .reply {
      padding: 0 10px 10px;
      margin-top: 10px;
      width: 100%;
      min-height: 60px;
      border-radius: 5px;
      background: #ddd8d83f;
      .headTxt {
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
      }
      .reolyCont {
        margin-top: 3px;
        font-size: 14px;
        color: #8c8c8c;
      }
    }
  }
}
</style>

<style lang="scss">
.evaluate {
  .van-nav-bar .van-icon {
    color: #000;
  }
  .van-nav-bar__text {
    color: #ff8000;
  }
  .van-cell:not(:last-child)::after {
    border: 0;
  }
}
</style>
